import React, { Suspense, lazy } from 'react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom'
import { InitialContext, ResultsContext } from './shared/context/ResultsContext'
import type {
  IBrowserCheckResultsData,
  IResultsData,
} from './shared/interface/resultsInterface'
import './style.scss'
import { LayoutTemplate } from './templates/LayoutTemplate'

import { createRoot } from 'react-dom/client'

const ErrorPage = lazy(() => import('./pages/Error/ErrorPage'))
const IPErrorMsg = lazy(
  () => import('./components/organisms/IPErrorMsg/IPErrorMsg.component')
)
const LandingPage = lazy(() => import('./pages/LandingPage/LandingPage'))
const HomePage = lazy(() => import('./pages/Home/HomePage'))
const FormPage = lazy(() => import('./pages/Form/FormPage'))
const ResultPage = lazy(() => import('./pages/Result/ResultPage'))
const WebBrowserHomePage = lazy(
  () => import('./pages/web-browser/Home/WebBrowserHomePage')
)
const WebBrowserFormPage = lazy(
  () => import('./pages/web-browser/Form/WebBrowserFormPage')
)
const WebBrowserResultPage = lazy(
  () => import('./pages/web-browser/Result/WebBrowserResultPage')
)
const EmailSecurityHomePage = lazy(
  () => import('./pages/email-security/Home/EmailSecurityHomePage')
)
const EmailSecurityFormPage = lazy(
  () => import('./pages/email-security/Form/EmailSecurityFormPage')
)
const EmailSecurityResultPage = lazy(
  () => import('./pages/email-security/Result/EmailSecurityResultPage')
)
const defaultResultContext = {
  resultsData: InitialContext.resultsData,
  setResultsData: (newResultsData: IResultsData) => {
    defaultResultContext.resultsData = newResultsData
  },
  browserCheckResultsData: InitialContext.browserCheckResultsData,
  setBrowserCheckResultsData: (
    newBrowserResultsData: IBrowserCheckResultsData
  ) => {
    defaultResultContext.browserCheckResultsData = newBrowserResultsData
  },
  ipAddress: InitialContext.ipAddress,
  setIpAddress: (ip: string) => {
    defaultResultContext.ipAddress = ip
  },
}
interface IUnkownRouteHandler {
  to: string
}
const UnkownRouteHandler = ({ to }: IUnkownRouteHandler) => {
  const prevRoute = useLocation()

  return <Navigate to={to} state={{ prevRoute }} replace={true} />
}
const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <Router>
    <LayoutTemplate>
      <ResultsContext.Provider value={defaultResultContext}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LandingPage />} />

            <Route path="/ip-check" element={<HomePage />} />
            <Route path="/ip-check/form" element={<FormPage />} />
            <Route path="/ip-check/results/:id" element={<ResultPage />} />
            <Route path="/browser-check" element={<WebBrowserHomePage />} />
            <Route
              path="/browser-check/form"
              element={<WebBrowserFormPage />}
            />
            <Route
              path="/browser-check/results/:id"
              element={<WebBrowserResultPage />}
            />
            <Route
              path="/email-security-check"
              element={<EmailSecurityHomePage />}
            />
            <Route
              path="/email-security-check/form"
              element={<EmailSecurityFormPage />}
            />
            <Route
              path="/email-security-check/results"
              element={<EmailSecurityResultPage />}
            />

            <Route path="/ip-error" element={<IPErrorMsg />} />
            <Route path="/404" element={<ErrorPage />} />
            <Route path="/*" element={<UnkownRouteHandler to="/404" />} />
          </Routes>
        </Suspense>
      </ResultsContext.Provider>
    </LayoutTemplate>
  </Router>
)
